import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Avatar from 'components/Avatar'

const StyledSpecialistIcon = styled.span`
  margin-left: -25px;
  border: 4px solid ${({ outlineColor }) => outlineColor};
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  width: 140px;

  ${({ extraSmall }) =>
    extraSmall &&
    css`
      width: 50px;
      margin-left: -10px;
      border-width: 2px;
      img {
        width: 50px;
        height: 50px;
      }
    `}

  ${({ small }) =>
    small &&
    css`
      width: 70px;
      margin-left: -10px;
      border-width: 2px;
      img {
        width: 70px;
        height: 70px;
      }
    `}

  ${({ smallMedium }) =>
    smallMedium &&
    css`
      width: 85px;
      margin-left: -20px;
      border-width: 2px;
      img {
        width: 85px;
        height: 85px;
      }
    `}

  ${({ medium }) =>
    medium &&
    css`
      width: 96px;
      margin-left: -20px;
      border-width: 2px;
      img {
        width: 96px;
        height: 96px;
      }
    `}
`

const Specialist = ({
  name,
  title,
  image,
  extraSmall,
  small,
  smallMedium,
  medium,
  outlineColor,
}) => {
  let size = 140
  const firstName = name.split(' ')[0]

  if (extraSmall) {
    size = 50
  } else if (small) {
    size = 70
  }

  return (
    <StyledSpecialistIcon
      extraSmall={extraSmall}
      small={small}
      smallMedium={smallMedium}
      medium={medium}
      outlineColor={outlineColor}
    >
      <Avatar
        imageSrc={image.file.url}
        alt={`Photo of ${firstName}, ${title}`}
        size={size}
        stretch
      />
    </StyledSpecialistIcon>
  )
}

Specialist.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  extraSmall: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  smallMedium: PropTypes.bool.isRequired,
  medium: PropTypes.bool.isRequired,
  outlineColor: PropTypes.string.isRequired,
}

export default Specialist
