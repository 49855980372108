import React from 'react'
import { Image } from '@farewill/ui'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'

const Probate = () => (
  <>
    <a
      href="https://uk.trustpilot.com/review/farewill.com"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        path={AWARD_LOGO_URL.TRUSTPILOT}
        width={100}
        height={60}
        alt={AWARD_STRAPLINE.TRUSTPILOT}
      />
    </a>
    <a
      href="https://www.modernlawawards.co.uk"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        path={AWARD_LOGO_URL.MODERN_LAW_AWARDS}
        width={100}
        height={60}
        alt={AWARD_STRAPLINE.MODERN_LAW_AWARDS}
      />
    </a>
    <a
      href="https://www.britishwillsandprobateawards.co.uk/winners-2021"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        path={AWARD_LOGO_URL.BRITISH_WILLS_AND_PROBATE_AWARDS}
        width={100}
        height={60}
        alt={AWARD_STRAPLINE.BRITISH_WILLS_AND_PROBATE_AWARDS}
      />
    </a>
  </>
)

export default Probate
