import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import useGetSpecialists from 'lib/specialists/useGetSpecialists'
import { specialistGroups } from 'lib/specialists/constants'
import { SPECIALISTS } from 'lib/products/constants'
import Specialist from './Specialist'

const StyledSpecialistContainer = styled(Wrapper)`
  display: inline-flex;
  flex-direction: row-reverse;
  padding-left: 25px;
  width: auto;
  max-width: 100%;

  ${({ $small, $extraSmall }) =>
    ($small || $extraSmall) &&
    css`
      margin: 0;
      padding-left: 10px;
    `}

  ${({ $smallMedium }) =>
    $smallMedium &&
    css`
      padding-left: 20px;
    `}

  ${({ $medium }) =>
    $medium &&
    css`
      padding-left: 20px;
    `}
`

const Specialists = ({
  max,
  extraSmall,
  small,
  smallMedium,
  medium,
  outlineColor,
  group,
}) => {
  const teamMembers = useGetSpecialists(group)
  /**
   * To ensure the team members display in the same order as they are on
   * contentful, we need to reverse the order, because this component creates the
   * avatars going from right-to-left.
   * And, because Probate team members have a specific priority order, we
   * reverse the order only after removing any team members
   */
  const orderedTeamMembers =
    group === SPECIALISTS.PROBATE
      ? teamMembers.slice(0, max).reverse()
      : teamMembers.slice().reverse().slice(0, max)

  return (
    <StyledSpecialistContainer
      $extraSmall={extraSmall}
      $small={small}
      $smallMedium={smallMedium}
      $medium={medium}
    >
      {orderedTeamMembers.map(({ id, name, title, image }) => (
        <Specialist
          key={id}
          name={name}
          title={title}
          image={image}
          extraSmall={extraSmall}
          small={small}
          smallMedium={smallMedium}
          medium={medium}
          outlineColor={outlineColor}
        />
      ))}
    </StyledSpecialistContainer>
  )
}

Specialists.propTypes = {
  max: PropTypes.number,
  extraSmall: PropTypes.bool,
  small: PropTypes.bool,
  smallMedium: PropTypes.bool,
  medium: PropTypes.bool,
  outlineColor: PropTypes.string,
  group: PropTypes.oneOf(specialistGroups).isRequired,
}

Specialists.defaultProps = {
  max: 4,
  extraSmall: false,
  small: false,
  smallMedium: false,
  medium: false,
  outlineColor: COLOR.WHITE,
}

export default Specialists
