import { SPECIALISTS } from 'lib/products/constants'

export const specialistQueries = {
  [SPECIALISTS.PROBATE]: 'probateSpecialistGroup',
  [SPECIALISTS.FUNERAL]: 'cremationSpecialistGroup',
  [SPECIALISTS.FUNERAL_PLAN]: 'cremationSpecialistGroup',
  [SPECIALISTS.FUNERAL_PLAN_EXPERIMENT]: 'cremationSpecialistGroup',
  [SPECIALISTS.WILLS]: 'willsSpecialistGroup',
  [SPECIALISTS.LEGAL]: 'legal',
  [SPECIALISTS.TELEPHONE_WILLS]: 'telephoneWillsSpecialistGroup',
  [SPECIALISTS.FUNERAL_COORDINATORS]: 'funeralCoordinators',
  [SPECIALISTS.FUNERAL_CELEBRANTS]: 'funeralCelebrants',
  [SPECIALISTS.FUNERAL_AWARDS]: 'funeralAwards',
  [SPECIALISTS.CROSS_TEAM]: 'crossTeam',
  [SPECIALISTS.PARTNERSHIPS_ACCOUNT_MANAGERS]: 'partnershipsAccountManagers',
}

export const specialistGroups = Object.keys(specialistQueries)
