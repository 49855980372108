import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Checkbox } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'

const StyledPreviewModeSwitch = styled.div`
  position: fixed;
  bottom: 80px;
  right: 40px;
  width: auto;
  background: ${COLOR.ACCENT.PRIMARY};
  padding: 10px 30px;
  border-radius: ${GTR.S};
  z-index: 2;

  legend {
    ${smallCaps}
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    transform: translateY(15px);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    legend {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const PreviewModeSwitch = ({ isPreviewMode, hasLiveMode, onChange }) => (
  <StyledPreviewModeSwitch>
    {!hasLiveMode && <legend>PAGE IS NOT LIVE YET</legend>}
    <Checkbox
      checked={isPreviewMode}
      onChange={onChange}
      label="Preview mode"
    />
  </StyledPreviewModeSwitch>
)

PreviewModeSwitch.propTypes = {
  isPreviewMode: PropTypes.bool.isRequired,
  hasLiveMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PreviewModeSwitch
