import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, H, P, Image, Grid } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import PreventOrphan from 'components/PreventOrphan'
import Cta from 'components/Cta'
import LogoSvg from 'assets/logo-icon-large.svg'
import { LIVE_PRODUCTS } from 'lib/products/constants'
import AccoladesProduct from 'components/AccoladesProduct'
import WrapperWithEdge from './WrapperWithEdge'

const StyledWrapperWithEdge = styled(WrapperWithEdge)`
  &::first-child {
    transform: rotate(180deg) scaleX(-1);
  }
`

const StyledCloudWrapper = styled(Wrapper)`
  position: absolute;

  width: 56px;
  height: 36px;

  ${screenMin.m`
    width: 220px;
    height: 110px;
  `}

  top: ${GTR.S};
  right: ${GTR.S};

  ${screenMin.m`
    right: ${GTR.S};
  `}

  ${screenMin.l`
    right: 220px;
  `}
`

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: 0;
`

const StyledTopRightCloud = styled(StyledTexture)`
  top: 0;
  right: 0;
  display: none;

  ${screenMin.xl`
    display: block;
  `}
`

const StyledBottomLeftCloud = styled(StyledTexture)`
  bottom: 0;
  left: 0;
  display: none;

  ${screenMin.xl`
    display: block;
  `}
`
const Clouds = () => (
  <StyledCloudWrapper>
    <StyledTopRightCloud path="illustrations/cloud-2" width={85} />
    <StyledBottomLeftCloud path="illustrations/cloud-1" width={56} />
  </StyledCloudWrapper>
)

const Logo = () => (
  <Wrapper margin={['0', 0, 'L']}>
    <img src={LogoSvg} alt="Farewill logo" />
  </Wrapper>
)

const Footer = ({
  title,
  description,
  smallText,
  cta,
  ctaFooter,
  trustmarks,
  product,
  background,
}) => (
  <StyledWrapperWithEdge background={background}>
    <Grid container gap={['XL', 'M']} gapFromXL="XL">
      <Grid.Item
        spanFromM={10}
        startColumnFromM={2}
        spanFromL={8}
        startColumnFromL={3}
        spanFromXL={6}
      >
        <Logo />
        <H size="L" tag="h2" decorative>
          {title}
        </H>
        <P size={smallText ? 'M' : 'L'} color={COLOR.BLACK}>
          <PreventOrphan>{description}</PreventOrphan>
        </P>
        {cta && cta.type && (
          <Wrapper margin={['L', 0, 0, 0]} maxWidthInColumns={3}>
            <Cta
              type={cta.type}
              link={cta.link}
              text={cta.text}
              product={product}
              secondaryCta={cta.secondaryCta}
              showArrowIcon={cta.arrowIcon}
              primary={false}
              stretch={false}
              telephoneNumber={cta.phoneNumber}
            />
          </Wrapper>
        )}
        {ctaFooter ? (
          <Wrapper margin={['S', 0, 0, 0]}>{ctaFooter}</Wrapper>
        ) : null}
        <Wrapper padding={[0, 0, 'S']} margin={['XXL', 0]}>
          <AccoladesProduct trustmarks={trustmarks} />
        </Wrapper>
      </Grid.Item>
    </Grid>
    <Clouds />
  </StyledWrapperWithEdge>
)

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    type: PropTypes.string.isRequired,
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    secondaryCta: PropTypes.bool,
    arrowIcon: PropTypes.bool,
    phoneNumber: PropTypes.string,
  }),
  ctaFooter: PropTypes.element,
  trustmarks: PropTypes.string.isRequired,
  product: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
  background: PropTypes.string,
  smallText: PropTypes.bool,
}

Footer.defaultProps = {
  cta: null,
  ctaFooter: null,
  background: null,
  smallText: false,
}

export default Footer
