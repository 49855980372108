import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Wrapper, Image } from '@farewill/ui'
import { TRUSTMARKS } from './constants'
import Funerals from './Funerals'
import ProbateAndWills from './ProbateAndWills'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    z-index: 2;
  }
`

const StyledTexture = styled(Image)`
  max-width: 330px;
  margin-top: -24px;
  margin-left: -5px;
`

const AccoladesProduct = ({ trustmarks }) => (
  <>
    <StyledWrapper
      maxWidth={trustmarks === TRUSTMARKS.FUNERALS ? '280px' : '320px'}
    >
      {trustmarks === TRUSTMARKS.WILLS && <ProbateAndWills />}
      {trustmarks === TRUSTMARKS.PROBATE && <ProbateAndWills />}
      {trustmarks === TRUSTMARKS.FUNERALS && <Funerals />}
    </StyledWrapper>
    {trustmarks === TRUSTMARKS.FUNERALS ? (
      <StyledTexture
        path="textures/grey-dotted-underline-1"
        width={390}
        height={30}
      />
    ) : (
      <StyledTexture
        path="textures/grey-dotted-underline-1"
        width={400}
        height={35}
      />
    )}
  </>
)

AccoladesProduct.propTypes = {
  trustmarks: PropTypes.string.isRequired,
}

export default AccoladesProduct
