import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BackgroundImageWrapper } from '@farewill/ui'
import { COLOR, BREAKPOINT } from '@farewill/ui/tokens'

const StyledEdge = styled(BackgroundImageWrapper)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 14%;
  color: ${COLOR.ACCENT.PRIMARY};
  transform: scaleX(-1) rotate(180deg);
  margin: -1% 0 -1px;

  background-color: ${({ background }) => background};
  svg {
    position: absolute;
  }
`

const Edge = ({ background }) => (
  <StyledEdge
    imagePath="textures/edge-2"
    imageWidth={BREAKPOINT.S}
    imageWidthFromS={BREAKPOINT.M}
    imageWidthFromM={BREAKPOINT.L}
    imageWidthFromL={BREAKPOINT.XL}
    backgroundPosition="center"
    cover
    background={background}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2592 156">
      <path d="M2592 0v19s-734 105-1339 105S0 18 0 18V0h2592z" />
    </svg>
  </StyledEdge>
)

Edge.propTypes = {
  background: PropTypes.string,
}

Edge.defaultProps = {
  background: 'transparent',
}

export default Edge
