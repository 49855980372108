import {
  groupByType,
  addGuideContextDataToArticles,
} from 'lib/contentful/articles'
import { normalizeArray } from 'lib/graphql/normalize'
import { ARTICLE_TYPES } from 'lib/contentful/constants'

import { PREVIEW_MODE_API_URL } from './constants'

export const getDynamicContentForArticle = async (
  articleType,
  { slug, guideSlug, nextArticleInGuideSlug }
) =>
  fetch(
    `${PREVIEW_MODE_API_URL}/${articleType}/?slug=${slug}&guideSlug=${guideSlug}&nextArticleInGuideSlug=${nextArticleInGuideSlug}`
  ).then((res) => res.json())

export const getSlugs = (data, slug) => {
  let slugs = {
    contentLastModified: new Date().toISOString(),
    guideSlug: undefined,
    nextArticleInGuideSlug: undefined,
    slug,
  }

  const contentfulGuides = normalizeArray(data.allContentfulGuide)
  const contentfulArticles = normalizeArray(data.allContentfulArticle)

  const { [ARTICLE_TYPES.HELP]: contentfulHelpArticles } =
    groupByType(contentfulArticles)

  addGuideContextDataToArticles(
    contentfulHelpArticles,
    contentfulGuides
  ).forEach((article) => {
    if (slug === article.slug) {
      slugs = {
        slug: article.slug,
        guideSlug: article.guideSlug,
        nextArticleInGuideSlug: article.nextArticleInGuideSlug,
        contentLastModified: article.reviewedAt || article.updatedAt,
      }
    }
  })

  return slugs
}
