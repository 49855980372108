import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Edge from './Edge'

const StyledContent = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 1;

  && {
    margin: -2% 0 0;
  }
`

const WrapperWithEdge = ({ children, background }) => (
  <div>
    <Edge background={background} />

    <Wrapper background={COLOR.ACCENT.PRIMARY}>
      <StyledContent>{children}</StyledContent>
    </Wrapper>
  </div>
)

WrapperWithEdge.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
}

WrapperWithEdge.defaultProps = {
  background: null,
}

export default WrapperWithEdge
