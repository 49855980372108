import { TRUSTPILOT_RATING_OUT_OF_5 } from 'config'

export const AWARD_LOGO_URL = {
  ACCOLADES_WILLS_PROBATE_LAW_FUNERALS_NO_YEAR:
    'external-logos/company-awards-no-year-2',
  ACCOLADES_LAW_WILLS_PROBATE: 'external-logos/accolades-2021',
  ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS:
    'external-logos/accolades-with-spaced-shadows-2021',
  ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS_2024:
    'external-logos/accolades-probate-2024-2',
  BRITISH_WILLS_AND_PROBATE_AWARDS:
    'external-logos/badge-british-wills-probate-awards-2022',
  BRITISH_WILLS_AND_PROBATE_AWARDS_NO_YEAR:
    'external-logos/wills-and-probate-awards-no-year',
  BRITISH_WILLS_AND_PROBATE_AWARDS_SIGNATURE:
    'external-logos/probate-provider-of-the-year-2021-signature',
  BRITISH_WILLS_AND_PROBATE_AWARDS_SIGNATURE_WITH_DASHES:
    'external-logos/probate-provider-of-the-year-2021-signature-with-dashes',
  BRITISH_WILLS_AND_PROBATE_AWARDS_VERTICAL:
    'external-logos/probate-provider-of-the-year-2021-vertical',
  GOOD_FUNERAL_AWARDS: 'external-logos/good_funeral_awards_2022',
  GOOD_FUNERAL_AWARDS_WITH_DASHES:
    'external-logos/good_funeral_awards_2022_with_dashes',
  GOOD_FUNERAL_AWARDS_NO_YEAR: 'external-logos/good_funeral_awards_light_hq',
  MODERN_LAW_AWARDS: 'external-logos/badge-modern-law-awards-2020-no-border',
  MODERN_LAW_AWARDS_NO_YEAR: 'external-logos/modern-law-awards-no-year',
  TRUSTPILOT: 'external-logos/badge-trustpilot',
  TRUSTPILOT_FIVE_STARS: 'external-logos/trustpilot-five-stars',
  TRUSTPILOT_RATING_WORDMARK: 'external-logos/trustpilot-rating-wordmark',
  WILL_WRITING_FIRM_OF_THE_YEAR_SIGNATURE:
    'external-logos/will-writing-firm-of-the-year-no-year-signature',
  WILL_WRITING_FIRM_OF_THE_YEAR_DECORATIVE:
    'external-logos/will-writing-firm-of-the-year-2019-2022-decorative',
  WILL_WRITING_FIRM_OF_THE_YEAR_DECORATIVE_COLOR:
    'external-logos/will-writing-firm-of-the-year-2019-2022-decorative-color-2',
  CUSTOMER_CARE_EXCELLENCE_DECORATIVE_COLOR:
    'external-logos/customer-care-excellence-award-2024-decorative-color',
}

export const AWARD_STRAPLINE = {
  ACCOLADES_LAW_WILLS_PROBATE: `${TRUSTPILOT_RATING_OUT_OF_5} out of 5 on Trustpilot, winner of Client Care Initiative of the Year at the Modern Law Awards in 2020, winner of Probate Provider of the Year (Small Firms) at the British Wills and Probate Awards in 2021`,
  BRITISH_WILLS_AND_PROBATE_AWARDS:
    'Winner of Probate Provider of the Year (Small Firms) at the British Wills and Probate Awards in 2022',
  GOOD_FUNERAL_AWARDS:
    'Voted ‘Best Direct Cremation Provider’ at the 2022 Good Funeral Awards',
  MODERN_LAW_AWARDS:
    'Winner of Client Care Initiative of the Year at the Modern Law Awards in 2020',
  PROBATE_PROVIDER_OF_THE_YEAR: 'Probate Provider of the Year 2020 and 2021',
  TRUSTPILOT: `${TRUSTPILOT_RATING_OUT_OF_5} out of 5 on Trustpilot`,
}
