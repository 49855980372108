import React from 'react'
import { Image } from '@farewill/ui'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'

const Funerals = () => (
  <>
    <a
      href="https://uk.trustpilot.com/review/farewill.com"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        path={AWARD_LOGO_URL.TRUSTPILOT}
        width={100}
        height={60}
        alt={AWARD_STRAPLINE.TRUSTPILOT}
      />
    </a>
    <a
      href="https://www.agfdgoodfuneralawards.co.uk/nominees"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        path={AWARD_LOGO_URL.GOOD_FUNERAL_AWARDS}
        width={112}
        height={89}
        alt={AWARD_STRAPLINE.GOOD_FUNERAL_AWARDS}
      />
    </a>
  </>
)

export default Funerals
